angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('main.configuration_cards', {
            url: '/configuration/cards',
            views: {
                "main": {
                    controller: 'CardsCtrl',
                    templateUrl: 'web/main/configuration/cards/cards.tpl.html',
                    controllerAs: 'controller'
                }
            },

            data: {
                pageTitle: 'Cards'
            }
        });
    })

    .controller('CardsCtrl', function ($rootScope, $scope, session, payTPVService, $translate, fingerinkSession, addCardModal, swalService, directDebitService) {

        var that = this;

        that.paymentMethod = session.get().user.company.paymentMethod;
        if (that.paymentMethod != 'PAYTPV') {
            directDebitService.getAccount().then(r => {
                that.account = r.data;
                that.accountCopy = angular.copy(that.account);
            });
            that.updateAccount = () => directDebitService.updateAccount(that.accountCopy).then(r => {
                swal($translate.instant("¡Correcto!"), $translate.instant("Se ha cambiado la cuenta bancaria correctamente"), "success");
                that.account = r.data;
                that.accountCopy = angular.copy(that.account);
            });
        } else {


            that.tarjetaDefault = session.get().user.company.defaultCard;
            $scope.$on("sessionChanged", function () {
                that.tarjetaDefault = session.get().user.company.defaultCard;
            });



            that.loadCards = function () {
                that.cardsMap = [];

                that.loading = true;

                payTPVService.getTarjetas().then(function (response) {
                    that.tarjetas = response.data;
                    for (var i = 0; i < that.tarjetas.length; i++) {
                        that.cardsMap[that.tarjetas[i].id] = that.tarjetas[i];
                    }
                    that.loading = false;

                });
            };

            that.loadCards();




            that.updateTarjetaDefault = function (tarjeta) {
                payTPVService.updateTarjetaDefault(tarjeta.id).then(function (response) {
                    swal($translate.instant("¡Correcto!"), $translate.instant("Se ha cambiado la tarjeta por defecto a esta"), "success");
                    fingerinkSession.renoveToken();
                }, function (error) {
                    swal($translate.instant('Algo ha ido mal'), $translate.instant("No se ha podido cambiar la tarjeta por defecto"), "error");
                });
            };



            that.addTarjeta = function () {
                addCardModal.openModal().result.then(function (data) {
                    if (data) {
                        swal($translate.instant("¡Correcto!"), $translate.instant("Se ha añadido la tarjeta correctamente"), "success");
                        payTPVService.getTarjetas().then(function (response) {
                            fingerinkSession.renoveToken();
                            that.loadCards();
                        });
                    }
                });
            };





            that.deleteTarjeta = function (id) {
                swalService.requestSwal('¿Eliminar?', 'Se va a proceder a eliminar la tarjeta', 'info', () =>
                    payTPVService.deleteTarjeta(id)).then(function (response) {
                        swal($translate.instant('Tarjeta eliminada'), $translate.instant('Se ha eliminado la tarjeta correctamente'), 'success');
                        payTPVService.getTarjetas().then(function (response) {
                            that.loadCards();
                            fingerinkSession.renoveToken();
                        });
                    }, function (response) {
                        that.loadCards();
                        swal($translate.instant('Algo ha ido mal'), $translate.instant('No se ha podido eliminar la tarjeta'), 'error');
                    });
            };
        }
    });
